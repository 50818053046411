.orgcomp__menu{
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    top: 65px;
    right: 0;
    width: 100%;
    left: 0;
    //box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    border:1px solid #DBDBDB !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    overflow: auto;
    height: auto !important;
    max-height: 380px !important;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 15 !important;
}

.orgcomp__menu::-webkit-scrollbar {
    display: none;
}

.orgcomp__menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.orgcomp__menu.active {
opacity: 1;
visibility: visible;
transform: translateY(0);
}

.orgcomp__menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.orgcomp__menu li span {
    text-decoration: none;
    padding: 10px 16px 10px 16px;
    display: block;
}

.orgcomp__menu li:hover span {
    background:'#ccc !important';
    cursor:'pointer'
}

.org__email {
    padding: 4px 8px !important;
}

.return__email {
    padding: 10px 14px 10px 14px !important;
    border-radius: 4px !important;
    display: flex;
    background-color: #f9f9f9 !important;
    border: 1px solid rgba(0,0,0, 0.1) !important;
}

.comp__dragcontainer {
    width: 100% !important;
    box-sizing: border-box;
}

.comp__dragcontainer {
  // display: block !important;
  // height: 6rem !important;
  // align-items: center;
  // cursor: pointer;
  // border-radius: 4px !important;   
  //margin: 1rem !important;
  //max-width: 610 !important;
}

.text_ellipsis {
    max-width: auto; 
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
}

.company_checklist_style {
    display: flex; 
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 4px !important;
}

.company_checklist_style1 {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    border-radius: 4px;
    margin-bottom: 8px !important;
    background: #f7f7f7;
    padding: 6px; 
    margin-bottom: 8px;
}

.icon_hover {
    visibility: hidden;
}

.company_checklist_style2 {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    border-radius: 4px;
    margin-bottom: 8px !important;
    padding: 6px; 
    margin-bottom: 8px;
    &:hover {
        background: #f7f7f7;
        .icon_hover {
            visibility: visible;
        }
    }
    
}

.modal__main {
    margin-top: 16px;
    padding: 0px 16px 8px 16px;
    height: calc(100vh - 180px);
    overflow: auto !important;
}

.modal__main::-webkit-scrollbar {
    display: none;
}

/**Modal Footer**/
.modal__footer {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0;
    right:0;
    padding: 12px 0px 12px 25px;
    z-index:15;
    background: #fff;
    border-top: 1px solid #ccc;
}

.modal__button {
    min-width: 130px !important;
    color: white;
    margin-right: 3% !important;
}

.menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 40px;
    right: 0;
    width: 180px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    z-index: 15 !important;
}

.menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu li a {
    text-decoration: none;
    color: #333333;
    padding: 7px 7px;
    display: block;
}

.menu ul li a:hover {
    background:'#ccc';
    border-radius: 8px;
}

.header_icons {
    color: black !important;
    font-size: 18px !important;
    min-width: 30px !important;
    line-height: 10px !important;
}

.header_back_icon {
    color: #666 !important;
    font-size: 16px !important;
    min-width: 20px !important;
    line-height: 10px !important;
    padding: 4px !important
}

.clear_triage_stop_followup {
    filter: invert(19%) sepia(99%) saturate(5925%) hue-rotate(342deg) brightness(89%) contrast(93%);
}

.companyChecklistStyle {
    margin-top: 16px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 8px;
}

.stickyTopBar {
    position: sticky !important; 
    top: 0 !important;
    z-index: 999 !important; 
    background-color: #f7f7f7 !important;
}

.stickyTopBar1 {
    position: sticky !important; 
    top: 54px !important;
    z-index: 999 !important; 
    background-color: #f7f7f7 !important;
}

.stickyTopBar2 {
    position: sticky !important; 
    top: 32px !important;
    z-index: 999 !important; 
    background-color: #f7f7f7 !important;
}

.inboxIcon {
    width: 18px; 
    margin-right: 8px;
}

.filterColor {
    filter: invert(10%) sepia(53%) saturate(0%) hue-rotate(314deg) brightness(20%) contrast(96%);
}

.filterColor1 {
    filter: invert(10%) sepia(53%) saturate(0%) hue-rotate(314deg) brightness(90%) contrast(96%);
}

.filterColor2 {
    filter: invert(10%) sepia(53%) saturate(0%) hue-rotate(314deg) brightness(60%) contrast(96%);
}

.text_ellipsis1 {
    max-width: 210px; 
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
}
