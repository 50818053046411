.mentions {
    height: 2.5rem !important;
    font-size: 15px !important;
}

.mentions--singleLine .mentions__control {
    display: inline-block;
}

.mentions--singleLine .mentions__higlighter {
    padding: 1px;
    border: 2px inset transparent;

}

.mentions--singleLine .mentions__input {
    padding: 5px;
    border: 2px inset;
}

.mentions--multiLine .mentions__control {
    // font-family: monospace;
    // font-size: 14pt;
    border: 1px solid silver;
    height: 7rem !important;
    border-radius: 5px !important;
}

.mentions--multiLine .mentions__highlighter {
    padding: 9px;
}

.mentions--multiLine .mentions__input {
    padding: 9px;
    min-height: 63px;
    outline: 0;
    border: 0;
}

.mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 2px !important;
    font-weight: 600 !important;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
    background-color: silver !important;
}

.mentions__mention {
    background-color: #E8F5FB;
}

.mentionWrapper {
    //z-index: 999 !important;
    position: relative;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input {
    width: 100%;
    //background: transparent;
    //font-size: 0.9rem;
    color: gray; // #a9b5c4;
    margin: 4px auto; //8px;
    border: 1px solid rgb(219, 219, 219) !important;
    padding: 8px 0px 8px 12px !important;
    border-radius: 5px !important;
    position: relative;
}

.mentionWrapper--singleLine .mentionWrapper__higlighter {
    padding: 1px;
    border: 2px inset transparent;

}

.mentionWrapper--multiLine .mentionWrapper__control {
    // font-family: monospace;
    // font-size: 14pt;
    border: 1px solid silver;
    height: 7rem !important;
    border-radius: 5px !important;

}

.mentionWrapper--multiLine .mentionWrapper__highlighter {
    padding: 9px;
}

.mentionWrapper--multiLine .mentionWrapper__input {
    padding: 9px;
    min-height: 63px;
    outline: 0;
    border: 0;
    margin-left: 16px;
}
.mentionWrapper__suggestions{
    z-index: 999 !important;
}
.mentionWrapper__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 2px !important;
    font-weight: 600 !important;
}

.mentionWrapper__suggestions__item {
    padding: 5px 15px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentionWrapper__suggestions__item--focused {
    background-color: silver !important;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input::placeholder {
    color: #ccc; //#7288a3;
    font-weight: 400;
    font-size: 14px;
}


.mentionWrapper .mentionWrapper__control {
    height: 30px !important;
    min-height: 30px !important;
    border: none;
    z-index: 999 !important;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__highlighter {
    padding: 0.7rem 1rem;
    background-color: white;
    max-height: 100% !important;
}

.notesContainer {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px !important;
    // padding: 5px !important;
    padding: 8px !important;
    border: 1px solid snow !important;
    // box-shadow: -1px 2px 0px 0px #e1d8d845 !important;
    margin-left: 3px !important;
    margin-top: 6px !important;
}

.noteFullName {
    margin-left: 6px !important;
    margin-top: 6px !important;
    color: black !important;
    font-size: 15px !important;
    font-weight: 500 !important;
}

.noteTime {
    margin-left: 8px !important;
    margin-top: 6.5px !important;
    color: #555555 !important;
    font-size: 13px !important;
}

.noteText {
    display: inline-block !important;
    font-size: 14px !important;
    color: #000000;
    padding: 5px !important;
}

.loggedInUser {
    color:' blue'
}



.notesMain {
    // background-color: red;
    height: auto !important;
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    -ms-transform: translate3d(0, 0, 0) !important;
    -o-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}

.notesIcons {
    display: none !important;
}

.notesParent :hover {
    .notesIcons {
        display: flex !important;
        margin: auto 0;
    }
}


.notesMain {
    .horizontalDivider {
        width: 1px !important;
        // max-height: 2rem !important;
        // height:auto !important;
        background-color: #cacaca !important;
        margin-top: 0px !important;
        border: none !important;
        margin-bottom: 0px !important;
        margin-left: 15px !important;
    }
}

.notesParent:last-child .horizontalDivider {
    display: none;
}

.box {
    width: 100%;
    background-color: #fff;
    color: #333;
    padding: 20px;
    position: relative;
    float: left;
    margin-bottom: 16px;
}

.box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -5px;
    top: 0px;
    border-top: 7px solid transparent;
    border-right: 7px solid #fff;
    border-left: none;
    border-bottom: 7px solid transparent;
}