@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?b858757b642ea06faa11392bd93f0762") format("truetype"),
url("./flaticon.woff?b858757b642ea06faa11392bd93f0762") format("woff"),
url("./flaticon.woff2?b858757b642ea06faa11392bd93f0762") format("woff2"),
url("./flaticon.eot?b858757b642ea06faa11392bd93f0762#iefix") format("embedded-opentype"),
url("./flaticon.svg?b858757b642ea06faa11392bd93f0762#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-folder:before {
    content: "\f101";
}
.flaticon-flash:before {
    content: "\f102";
}
.flaticon-tag:before {
    content: "\f103";
}
.flaticon-calendar:before {
    content: "\f104";
}
.flaticon-clock:before {
    content: "\f105";
}
.flaticon-bin:before {
    content: "\f106";
}
.flaticon-chat:before {
    content: "\f107";
}
.flaticon-check:before {
    content: "\f108";
}
.flaticon-check-1:before {
    content: "\f109";
}
.flaticon-check-2:before {
    content: "\f10a";
}
.flaticon-cancel:before {
    content: "\f10b";
}
.flaticon-check-3:before {
    content: "\f10c";
}
.flaticon-add:before {
    content: "\f10d";
}
.flaticon-check-4:before {
    content: "\f10e";
}
.flaticon-upload:before {
    content: "\f10f";
}
.flaticon-minus:before {
    content: "\f110";
}
.flaticon-download:before {
    content: "\f111";
}
.flaticon-minus-1:before {
    content: "\f112";
}
.flaticon-cancel-1:before {
    content: "\f113";
}
.flaticon-settings:before {
    content: "\f114";
}
.flaticon-clipboard:before {
    content: "\f115";
}
.flaticon-attachment:before {
    content: "\f116";
}
.flaticon-add-user:before {
    content: "\f117";
}
.flaticon-remove-user:before {
    content: "\f118";
}
.flaticon-ring:before {
    content: "\f119";
}
.flaticon-checked:before {
    content: "\f11a";
}
.flaticon-clip:before {
    content: "\f11b";
}
.flaticon-chat-1:before {
    content: "\f11c";
}
.flaticon-menu:before {
    content: "\f11d";
}
.flaticon-clock-1:before {
    content: "\f11e";
}
.flaticon-user:before {
    content: "\f11f";
}
.flaticon-group:before {
    content: "\f120";
}
.flaticon-down-arrow:before {
    content: "\f121";
}
.flaticon-settings-1:before {
    content: "\f122";
}
.flaticon-business-and-trade:before {
    content: "\f123";
}
.flaticon-price-tag:before {
    content: "\f124";
}
.flaticon-add-1:before {
    content: "\f125";
}
.flaticon-delete:before {
    content: "\f126";
}
.flaticon-right-arrow:before {
    content: "\f127";
}
.flaticon-filter:before {
    content: "\f128";
}
.flaticon-plus:before {
    content: "\f129";
}
.flaticon-sort:before {
    content: "\f12a";
}
.flaticon-search:before {
    content: "\f12b";
}
.flaticon-reload:before {
    content: "\f12c";
}
.flaticon-sort-1:before {
    content: "\f12d";
}
.flaticon-add-2:before {
    content: "\f12e";
}
.flaticon-clock-2:before {
    content: "\f12f";
}
.flaticon-comment:before {
    content: "\f130";
}
.flaticon-attachment-1:before {
    content: "\f131";
}
.flaticon-calendar-1:before {
    content: "\f132";
}
.flaticon-review:before {
    content: "\f133";
}
.flaticon-more:before {
    content: "\f134";
}
.flaticon-more-1:before {
    content: "\f135";
}
.flaticon-error:before {
    content: "\f136";
}
.flaticon-folder-1:before {
    content: "\f137";
}
.flaticon-check-5:before {
    content: "\f138";
}
.flaticon-sun:before {
    content: "\f139";
}
.flaticon-share:before {
    content: "\f13a";
}
.flaticon-export:before {
    content: "\f13b";
}
.flaticon-edit:before {
    content: "\f13c";
}
.flaticon-delete-1:before {
    content: "\f13d";
}
.flaticon-clipboards:before {
    content: "\f13e";
}
.flaticon-clipboards-1:before {
    content: "\f13f";
}
.flaticon-label:before {
    content: "\f140";
}
.flaticon-clipboards-2:before {
    content: "\f141";
}
.flaticon-clipboards-3:before {
    content: "\f142";
}
.flaticon-approval:before {
    content: "\f143";
}
.flaticon-customer-service:before {
    content: "\f144";
}
.flaticon-badge:before {
    content: "\f145";
}
.flaticon-pdf:before {
    content: "\f146";
}
.flaticon-doc:before {
    content: "\f147";
}
.flaticon-xls:before {
    content: "\f148";
}
.flaticon-ppt:before {
    content: "\f149";
}
.flaticon-jpg:before {
    content: "\f14a";
}
.flaticon-zip:before {
    content: "\f14b";
}
.flaticon-png:before {
    content: "\f14c";
}
.flaticon-txt:before {
    content: "\f14d";
}
.flaticon-file:before {
    content: "\f14e";
}
