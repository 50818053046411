.mentions1 {
    height: 9vh !important;
    font-size: 15px !important;
}

.mentions1--singleLine .mentions1__control {
    display: inline-block;
}

.mentions1--singleLine .mentions1__higlighter {
    padding: 1px;
    border: 2px inset transparent;

}

.mentions1--singleLine .mentions1__input {
    padding: 5px;
    border: 2px inset;
}

.mentions1--multiLine .mentions1__control {
    // font-family: monospace;
    // font-size: 14pt;
    height: 45px !important;
    background-color: #fff !important;
    border: 0px !important;
}

.mentions1--multiLine .mentions1__highlighter {
    padding: 9px;
}

.mentions1--multiLine .mentions1__input {
    padding: 3px 9px;
    outline: 0;
    border: 0;
    overflow-y: auto !important;
}

.mentions1--multiLine .mentions1__input::-webkit-scrollbar {
    display: none;
}

.mentions1__suggestions__list {
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    font-size: 14px !important;
    padding: 2px !important;
    font-weight: 600 !important;
    position: absolute !important;
    left: 0px !important;
    bottom:100% !important;
    transform: translate3d(8px 64px 0px) !important;
    will-change: transform !important;
    width: 300px !important;
}

.mentions1__suggestions__item {
    padding: 5px 15px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions1__suggestions__item--focused {
    background-color: silver !important;
}

.mentions1__mention {
    background-color: #E8F5FB;
}

.mentionWrapper1 {
    //z-index: 999 !important;
    position: relative;
}

.mentionWrapper1 .mentionWrapper1__control .mentionWrapper1__input {
    width: 100%;
    //background: transparent;
    //font-size: 0.9rem;
    color: gray; // #a9b5c4;
    margin: 4px auto; //8px;
    border: 1px solid rgb(219, 219, 219) !important;
    padding: 8px 0px 8px 12px !important;
    border-radius: 5px !important;
    position: relative;
}

.mentionWrapper1--singleLine .mentionWrapper1__higlighter {
    padding: 1px;
    border: 2px inset transparent;
}

.mentionWrapper1--multiLine .mentionWrapper1__control {
    // font-family: monospace;
    // font-size: 14pt;
    border: 1px solid silver;
    height: 7rem !important;
    border-radius: 5px !important;

}

.mentionWrapper1--multiLine .mentionWrapper1__highlighter {
    padding: 9px;
}

.mentionWrapper1--multiLine .mentionWrapper1__input {
    padding: 9px;
    min-height: 63px;
    outline: 0;
    border: 0;
    margin-left: 16px;
}
.mentionWrapper1__suggestions{
    z-index: 999 !important;
}
.mentionWrapper1__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 2px !important;
    font-weight: 600 !important;
}

.mentionWrapper1__suggestions__item {
    padding: 5px 15px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentionWrapper1__suggestions__item--focused {
    background-color: silver !important;
}

.mentionWrapper1 .mentionWrapper1__control .mentionWrapper1__input::placeholder {
    color: #ccc; //#7288a3;
    font-weight: 400;
    font-size: 14px;
}


.mentionWrapper1 .mentionWrapper1__control {
    height: 30px !important;
    min-height: 30px !important;
    border: none;
    z-index: 999 !important;
}

.mentionWrapper1 .mentionWrapper1__control .mentionWrapper1__highlighter {
    padding: 0.7rem 1rem;
    background-color: white;
    max-height: 100% !important;
}

.message {
    width: fit-content;
    max-width: 350px !important;
    position: relative;
    padding: 3px 10px 10px 10px;
    background: #fff;
    border-radius: 10px;
    border-top-left-radius: 0;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.messageOne {
    width: fit-content;
    max-width: 350px !important;
    position: relative;
    padding: 6px 10px;
    border-radius: 10px;
    margin-top: 1px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.loggedInUserMessage {
    position: relative;
    padding: 3px 10px 10px 10px;
    background: #e4f8d0;
    border-radius: 10px;
    margin-left: auto;
    border-top-right-radius: 0;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2);
    width: fit-content;
    max-width: 350px !important;
}

.message:before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    border: 10px solid transparent;
    border-top: 10px solid #fff;
}

.loggedInUserMessage:before {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    border: 10px solid transparent;
    border-top: 10px solid #e4f8d0;
}

.time {
    position: relative;
    display: block;
    font-size: 0.7em;
    width: 100%;
    text-align: end;
    color: #777;
}

.pending__background {
    background-color: #f9f9f9;
}

.in-process__background {
    background-color: rgba(236, 85, 0, 0.1)
}

.awaiting-reply__background {
    background-color: rgba(219, 76, 63, 0.1);
}

.pending-approval__background {
    background-color: rgba(0, 0, 0, 0.1);
}

.completed__status__background {
    background-color: rgba(59, 150, 27, 0.1);
}